import logo from './logo.svg';
import './App.css';
import { useState } from "react";
const munsell = require('munsell');

function App() {
  const [red,setRed] = useState(0);
  const [green,setGreen] = useState(0);
  const [blue,setBlue] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const setColor = (color, value) => {
    if(isNaN(parseFloat(value)) || !isFinite(value)){
      value = 0;
    }
    if(value > 255) value = 255;
    if(value < 0) value = 0;
    if (color == "red"){
      setRed(value);
    } else if (color == "green"){
      setGreen(value)
    } else if (color == "blue") {
      setBlue(value)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        R<input id="red" onChange={(event) => setColor("red",event.target.value)}></input>
        G<input id="green" onChange={(event) => setColor("green", event.target.value)}></input>
        B<input id="blue" onChange={(event) => setColor("blue", event.target.value)}></input>

      RGB:  {red}-{green}-{blue}<br />
      Munsell:  {munsell.rgb255ToMunsell(red,green,blue)}

      <div style={{ background: `rgb(${red},${green},${blue})`, width: "100px", height: "100px"}}></div>
      </header>
    </div>
  );
}

export default App;
